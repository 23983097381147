import {deepLake, theme, onlineLime, white, wisteria} from "../../../../../constants";

export const styles = {
  section: {
    background: deepLake,
    padding: "100px 5px"
  },
  title: {
    textAlign: "center",
    color: onlineLime,
  },
  subtitle: {
    color: white,
  },
  titleDivider: {
    height: 2,
    background: wisteria,
  },
  card: {
    padding: theme.spacing(5)
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: deepLake,
  }
};
