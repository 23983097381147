import React from "react";
import {
  Button, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, IconButton, Grid, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { FaMapMarkedAlt, MdLocationCity } from "react-icons/all";

import bgImage from "../../../../../assets/images/mint_museum-1.jpg";
import {locationEmbedUrl, locationMapUrl, locationWebsite} from "../../../../../constants";

import { styles } from "./constants";

const useStyles = makeStyles(styles);


export default function LocationSection() {
  const classes = useStyles();

  return (
    <Grid container className={classes.section} spacing={0} alignItems="center" justify="center">

      <Grid item  xs={12} md={7} justify="center" className={classes.cardItem}>
        <Card className={classes.card}>
          <CardHeader
            title="The Mint Museum Uptown"
            subheader="500 South Tryon Street | Charlotte, NC 28202"
          />
          <CardMedia
            className={classes.media}
            image={bgImage}
          />
          <CardContent>
            <Typography className={classes.paragraph} variant="body2" color="textSecondary" component="p">
              The Mint Museum, North Carolina’s first art museum with one of the largest collections
              in the Southeastern U.S. With the help of grants and the Arts & Science Council, the Mint Museum's new
              145,000-square-foot location opened on October 1, 2010. Designed by Machado and Silvetti Associates
              of Boston, the building's estimated cost is $57 million.

            </Typography>
            <Typography className={classes.paragraph} variant="body2" color="textSecondary" component="p">
              The Uptown location spreads over five floors
              and houses collections of glass, ceramics, wood and other material from  the Mint Museum of
              Craft + Design. Contemporary Art, American Art and some of the European Art collections from the
              Randolph Road facility have also moved to the new location, bringing the Mint's arts and craft and
              fine arts focuses under one roof for the first time. This location highlights the Museum's holdings
              in Ceramics, Historic Costume and Fashionable Dress, Ancient American Art, Asian Art, Coins & Currency,
              Decorative Arts, and Spanish Colonial Art.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="open maps" title="open maps" href={locationMapUrl} target="_blank">
              <FaMapMarkedAlt />
            </IconButton>
            <Button color="primary" href={locationWebsite} target="_blank">
              Learn More
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={11} md={4} className={classes.title}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.subtitle}>
              <MdLocationCity />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              Mint Museum Uptown
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.titleDivider} variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={clsx(classes.subtitle, "--cursive")}>
              at the Levine Center for the Arts in the City of Charlotte, North Carolina
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.iframeItem}>
            <iframe
              className={classes.iframe}
              src={locationEmbedUrl}
              frameBorder="0"
              allowFullScreen=""
              title="mint museum map"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
