import React from "react";
import {
  Avatar, Button, Card, CardActions, CardHeader, CardMedia, Divider, IconButton, Grid, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { GiGlassCelebration, GoCalendar } from "react-icons/all";
import { Link } from "react-scroll";

import bgImage from "../../../../../assets/images/engage-2.jpg";
import {addEventId, addEventUrl, weddingDateLabel} from "../../../../../constants";

import { styles } from "./constants";

const useStyles = makeStyles(styles);

export default function InvitationSection() {
  const classes = useStyles();

  return (
    <Grid container className={classes.section} spacing={0} alignItems="center" justify="center">
      <Grid item md={4} className={classes.title}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.subtitle}>
              <GiGlassCelebration />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className="--cursive">
              Please Join Us
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.titleDivider} variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={clsx(classes.subtitle, "--cursive")}>
              we request your presence on the second day of July in the year two thousand and twenty-one
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={7} className={classes.card}>
        <Card>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar}>
                SN
              </Avatar>
            }
            title="The Wedding Ceremony of Vanessa Hunter and Kenneth Seals-Nutt"
            subheader={weddingDateLabel}
          />
          <CardMedia
            className={classes.media}
            image={bgImage}
            title="Engagement Photograph of Kenneth and Vanessa"
          />
          <CardActions >
            <Link activeClass="active" to="rsvpSection" spy={true} smooth={true} duration={1000}>
              <Button color="primary">
                RSVP
              </Button>
            </Link>
            <IconButton
              title="Add to Calendar"
              className="addeventatc"
              data-id={addEventId}
              href={addEventUrl}
              target="_blank"
              rel="nofollow"
              aria-label="add to calendar"
            >
              <GoCalendar />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
