import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Parallax } from "react-parallax";

import bgImage from "../../../../../assets/images/charlotte-1.jpg";


const useStyles = makeStyles(theme => ({
  section: {
    minHeight: "60vh",
    background: "#ffffffdb",
  },
  title: {
    padding: theme.spacing(4),
    textAlign: "center",
  }
}));


export default function CharlotteImageSection(props) {
  const classes = useStyles();
  return (
    <Parallax blur={{ min: -5, max: 5 }} bgImage={bgImage} strength={200}>
      <Grid container className={classes.section} alignItems="center" justify="center">
        <Grid item className={classes.title}>
          <Typography variant="h1" color="primary" className="--cursive">
            The Queen City
          </Typography>
        </Grid>
      </Grid>
    </Parallax>
  );
}
