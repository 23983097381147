import React from "react";
import { Card, CardContent, CardMedia, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { GoCalendar } from "react-icons/all";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import amsterdamImage from "../../../../../assets/images/amsterdam-1.jpg";
import athleticsImage from "../../../../../assets/images/athletics-1.jpg";
import firstDateImage from "../../../../../assets/images/date-1.jpg";
import disneyImage from "../../../../../assets/images/disney-1.jpg";
import concertImage from "../../../../../assets/images/concert-1.png";
import weddingImage from "../../../../../assets/images/engage-1.jpg";
import firstImage from "../../../../../assets/images/dr-1.jpg";
import graduationImage from "../../../../../assets/images/grad-1.jpg";
import kennyCareerImage from "../../../../../assets/images/kenny_career-1.jpg";
import inNOutImage from "../../../../../assets/images/in_n_out-1.jpg";
import vacationImage from "../../../../../assets/images/vacation-1.jpg";
import harvardYaleImage from "../../../../../assets/images/harvard_yale-1.jpg";
import engagementPartyImage from "../../../../../assets/images/engagement_party-1.jpg";
import newOrleansImage from "../../../../../assets/images/new_orleans-1.jpg";
import proposalImage from "../../../../../assets/images/proposal-1.jpg";
import metImage from "../../../../../assets/images/met-1.jpg";
import vanessaCareerImage from "../../../../../assets/images/vanessa_career-1.jpg";
import stlImage from "../../../../../assets/images/stl-1.jpg";
import vineyardImage from "../../../../../assets/images/vineyard-1.jpg";
import warriorsImage from "../../../../../assets/images/warriors-1.jpg";

import { deepLake, onlineLime, white, wisteria } from "../../../../../constants";


const useStyles = makeStyles(theme => ({
  section: {
    background: "#e5dedf",
    padding: "100px 5px"
  },
  title: {
    textAlign: "center",
    color: onlineLime,
    paddingBottom: theme.spacing(5)
  },
  subtitle: {
    color: white,
  },
  timelineDivider: {
    margin: theme.spacing(2),
    background: wisteria,
  },
  card: {
    boxShadow: theme.shadows[4]
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    filter: "grayscale(.7)",
    transition: "all 2s",
    opacity: .8,

    "&:hover": {
      filter: 'none',
      opacity: 1,
    }
  },
  avatar: {
    backgroundColor: deepLake,
  },
  timelineElement: {
    // color: deepLake,
    padding: 0
  },
  timelineTitle: {

  }
}));


export default function TimelineSection() {
  const classes = useStyles();

  const renderElement = (title, date, location, image, description, Icon=GoCalendar) => (
    <VerticalTimelineElement
      className={classes.timelineElement}
      contentArrowStyle={{ borderRight: `7px solid ${deepLake}` }}
      contentStyle={{padding: 0, background: "none"}}
      iconStyle={{ background: deepLake, color: white }}
      icon={<Icon />}
    >
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={image} />
        <Divider variant="middle" className={classes.timelineDivider}/>
        <CardContent>
          <Typography variant="h6" color="primary" className="vertical-timeline-element-title">
            {title}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" className="vertical-timeline-element-subtitle">
            {date}
          </Typography>
          <Typography color="secondary" variant="overline">
            {location}
          </Typography>
        </CardContent>
      </Card>
    </VerticalTimelineElement>
  );

  return (
    <Grid container className={classes.section} spacing={0} alignItems="center" justify="center">
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h2" className="--cursive" color="secondary">
          Our Story
        </Typography>
      </Grid>
      <Grid item xs={11} md={7}>
        <VerticalTimeline>
          {renderElement("First Photo","January 2014", "Dominican Republic", firstImage,)}
          {renderElement("First Date","June 2014", "Charlotte, NC", firstDateImage)}
          {renderElement("First Vacation","July 2015", "North Myrtle Beach, SC", vacationImage,)}
          {renderElement("First Road Trip","June 2016", "St. Louis, MO", stlImage,)}
          {renderElement("First Van Gogh Sighting","December 2016", "New York City, NY", metImage,)}
          {renderElement("First Vineyard Tour","July 2017", "Point Reyes, CA", vineyardImage,)}
          {renderElement("First In-N-Out Burger","July 2017", "Los Angeles, CA", inNOutImage,)}
          {renderElement("First Concert","July 2017", "Los Angeles, CA", concertImage,)}
          {renderElement("First Harvard-Yale Game","November 2017", "New Haven, CT", harvardYaleImage,)}
          {renderElement("First St. Patrick's Day Parade","April 2018", "New Orleans, LA", newOrleansImage,)}
          {renderElement("We're Engaged!","April 2018", "Midtown Manhattan, NYC", proposalImage,)}
          {renderElement("College Graduations","May 2018", "Charlotte, NC & New Haven, CT", graduationImage,)}
          {renderElement("Engagement Party","June 2018", "Charlotte, NC", engagementPartyImage,)}
          {renderElement("First time at Disney World","July 2018", "Orlando, FL", disneyImage,)}
          {renderElement("Beginning our Careers","August 2018", "Oakland, CA", vanessaCareerImage,)}
          {renderElement("Beginning our Careers","August 2018", "Oakland, CA", kennyCareerImage,)}
          {renderElement("First A's Game","August 2019", "Oakland, CA", athleticsImage,)}
          {renderElement("First Europe Trip","September 2019", "Amsterdam, Netherlands", amsterdamImage,)}
          {renderElement("First Warriors Game","December 2019", "San Fransisco, CA", warriorsImage,)}
          {renderElement("Our Wedding","Coming Soon", "Charlotte, NC", weddingImage,)}
        </VerticalTimeline>
      </Grid>
    </Grid>
  );
}
