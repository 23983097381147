import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { deepLake, onlineLime, stormy, wisteria, plumPurple, weddingDate } from "../../../constants";

const colors = [
  [stormy, .05],
  [plumPurple, .1],
  [wisteria, .15],
  [onlineLime, .3],
  [deepLake, .4],
];


export default class Countdown extends Component {
  static propTypes = {
  };

  renderTime = (label, value) => {
    return (
      <div className="timer">
        <Typography className="value" variant="h5">
          {value}
        </Typography>
        <Typography className="text" color="primary">
          {label}
        </Typography>
      </div>
    );
  };

  renderTimer = (label, startValue, remainingValue, iterValue) => {
    return (
      <CountdownCircleTimer
        isPlaying
        startAt={(startValue - remainingValue)*iterValue}
        durationSeconds={(iterValue*startValue)-1}
        renderTime={(value) => this.renderTime(label, Math.floor(value/iterValue))}
        colors={colors}
        onComplete={() => [true, 0]}
        size={125}
      />
    )
  };

  render() {
    const timeRemaining = weddingDate - Date.now();
    const days = timeRemaining / (60*60*24*1000);
    const hours = (days % 1) * 24;
    const minutes = (hours % 1) * 60;
    const seconds = (minutes % 1) * 60;
    return (
      <Grid container direction="row" justify="center" spacing={3}>
        <Grid item xs={6} md={3}>
          {this.renderTimer("days", 365, days, 60*60*24)}
        </Grid>
        <Grid item xs={6} md={3}>
          {this.renderTimer( "hours", 24, hours, 60*60)}
        </Grid>
        <Grid item xs={6} md={3}>
          {this.renderTimer("minutes", 60, minutes, 60)}
        </Grid>
        <Grid item xs={6} md={3}>
          {this.renderTimer("seconds", 60, seconds, 1)}
        </Grid>
      </Grid>
    );
  };
}