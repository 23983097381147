import {deepLake, onlineLime, theme, white, wisteria} from "../../../../../constants";

export const styles = {
  section: {
    background: deepLake,
    padding: "100px 5px"
  },
  title: {
    textAlign: "center",
    color: onlineLime,
  },
  subtitle: {
    color: white,
  },
  titleDivider: {
    height: 2,
    background: wisteria,
  },
  cardItem: {
    padding: 30
  },
  card: {
    maxWidth: "100%",
    margin: "0 auto"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paragraph: {
    textIndent: 15,
    marginTop: 10,
  },
  iframeItem: {
    padding: theme.spacing(5)
  },
  iframe: {
    width: "100%"
  },
};
