export const styles = theme => ({
  section: {
    minHeight: "100vh",
    background: "#ffffffdb",
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15)
  },
  bgImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  container: {
    textAlign: "center",
  },
  subtitleDivider: {
    height: 2,
    marginTop: 5,
    background: theme.palette.secondary
  }
});
