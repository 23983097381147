import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from 'react-scroll';

import { styles } from "./constants";


const useStyles = makeStyles(styles);

export default function RSVPForm(props) {
  const { onClick } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={5} alignItems="center" justify="center">
      <Grid item xs={12}>
        <Link activeClass="active" to="googleFrom" spy={true} smooth={true} duration={500}>
          <Button variant="outlined" className={clsx(classes.button, classes.regretButton)} onClick={onClick}>
            <Typography variant="h4" className="--cursive">
              Open Form
            </Typography>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
