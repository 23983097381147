import { deepLake, wisteria } from "../../../../constants";

export const styles = {
  button: {
    margin: 15,
    padding: "10px 15px",
    color: deepLake
  },
  regretButton: {
    color: wisteria
  }
};
