import React from "react";
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { IoIosHeartEmpty } from "react-icons/all";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import IntroSection from "../../Section/Home/Intro";
import InvitationSection from "../../Section/Home/Invitation";
import RodeoImageSection from "../../Section/Home/RodeoImage";
import TimelineSection from "../../Section/Home/Timeline";
import CharlotteImageSection from "../../Section/Home/CharlotteImage";
import LocationSection from "../../Section/Home/Location";
import FAQSection from "../../Section/Home/FAQ";
import ScheduleSection from "../../Section/Home/Schedule";
import RSVPSection from "../../Section/Home/RSVP";
import RegistrySection from "../../Section/Home/Registry";

const sections = [
  IntroSection, InvitationSection, RodeoImageSection, TimelineSection, CharlotteImageSection,
  LocationSection, ScheduleSection, RSVPSection, RegistrySection, FAQSection,
];


const message = (
  <span>
    👋😃 Hi, we have decided to move our date to <b>7/2/2021</b> <br/>
    to protect the health and safety of our family and friends during COVID-19. <br/>
    Stay Safe everyone! 🏡 - Vanessa & Kenny
  </span>
);

const useStyles = makeStyles(theme => ({
  footer: {
    background: theme.palette.primary.main,
    padding: theme.spacing(5),
    textAlign: "center",
  },
  text: {
    color: theme.palette.background.paper,
  },
}));

function HomePage(props) {
  const classes = useStyles();
  const action = key => (
    <Button onClick={() => { props.closeSnackbar(key) }}>
        Got It
    </Button>
  );
  return (
    <Grid container xs={12}>
      {sections.map(Section => (
        <Grid item xs={12}>
          <Section />
        </Grid>
      ))}
      <Grid item xs={12} className={classes.footer}>
        <Button target="_blank" href="http://seals-nutt.com">
          <Typography variant="subtitle2" className={classes.text}>
            Designed with <IoIosHeartEmpty /> By Seals-Nutt
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {

  };
};

export default withRouter(connect(mapStateToProps)(withSnackbar(HomePage)));
