import React, { useState } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Parallax } from "react-parallax";
import { FaFileSignature } from "react-icons/all";

import bgImage from "../../../../../assets/images/engage-3.jpg";
import { googleRSVPFormUrl } from "../../../../../constants";
import RSVPForm from "../../../Form/RSVP";

import { styles } from "./constants";

const useStyles = makeStyles(styles);

export default function RSVPSection() {
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState(false);

  const onClick = () => setFormOpen(true);
  return (
    <Grid container className={classes.section} spacing={0} alignItems="center" justify="center" name="rsvpSection">
      <Grid item xs={12} md={5}>
        <Parallax blur={{ min: -5, max: 5 }} bgImage={bgImage} strength={200} bgImageStyle={styles.bgImage}>
          <Grid container className={classes.image}/>
        </Parallax>
      </Grid>
      <Grid item xs={12} md={7} className={classes.title}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={10}>
            <Typography variant="h3" className={classes.icon}>
              <FaFileSignature />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h3" className="--cursive">
              Will you be attending?
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Divider className={classes.titleDivider} variant="middle" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              In order to ensure we have proper accommodations, RSVP responses will
              be recorded until <b>April 28th, 2021</b> at the latest
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <RSVPForm onClick={onClick}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item xs={12}
        className={clsx(classes.formContainer, {
          [classes.formClosed]: !formOpen,
        })}
        name="googleFrom"
      >
        <iframe
          title="RSVP Form"
          className={classes.iframe}
          src={googleRSVPFormUrl}
          width="100%"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading RSVP Form…
        </iframe>
      </Grid>

    </Grid>
  );
}
