import React from "react";
import { Grid, List, ListItem, ListItemText, ListSubheader, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Parallax } from "react-parallax";

import bgImage from "../../../../../assets/images/charlotte-2.jpg";

import { Schedule } from "./constants";
import {gray, plumPurple} from "../../../../../constants";

const useStyles = makeStyles(theme => ({
  section: {
    minHeight: "60vh",
    // background: "#ffffffdb",
    background: "#7dabb045",
    padding: theme.spacing(2),
  },
  title: {
    color: plumPurple,
    textAlign: "center",
    marginTop: 35
  },
  paper: {
    marginBottom: 100,
    padding: theme.spacing(5)
  },
  root: {
    maxWidth: "90%",
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: "70vh",
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listItem: {
    borderBottom: `1px dotted #cfc3c5`,
    width: "80%",
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    marginBottom: 35
  },
  time: {
    color: gray,
  }
}));

export default function ScheduleSection(props) {
  const classes = useStyles();
  return (
    <Parallax blur={{ min: -5, max: 5 }} bgImage={bgImage} strength={200}>
      <Grid container className={classes.section} spacing={5} alignItems="center" justify="center">
        <Grid item className={classes.title} xs={12}>
          <Typography variant="h1" className="--cursive">
            Schedule
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper elevation={3} className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} md={10}>
                <List className={classes.root} subheader={<li />}>
                  {Schedule.map(({name, agenda}, index) => (
                    <li key={`schedule-section-${index}`} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader>
                          <Typography variant="h5" color="primary" className="--cursive">
                            {name}
                          </Typography>
                        </ListSubheader>
                        {agenda.map(({time, title, details, location}, agendaIndex) => (
                          <ListItem
                            key={`schedule-item-${index}-${agendaIndex}`}
                            alignItems="flex-start"
                          >
                            <ListItemText primary={time} className={classes.time} />
                            <ListItemText
                              className={classes.listItem}
                              primary={(
                                <Typography variant="subtitle1" color="primary">
                                  {title}
                                </Typography>
                              )}
                              secondary={(
                                <Typography>
                                  <Typography variant="subtitle2" className={classes.time}>
                                    {details}
                                  </Typography>
                                  <Typography variant="caption" color="textSecondary">
                                    {location.name}
                                  </Typography>
                                  <br />
                                  <Typography variant="caption">
                                    {location.address}
                                  </Typography>
                                </Typography>
                              )}
                            />
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Parallax>
  );
}
