import React from "react";
import { Card, CardHeader, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { Parallax } from "react-parallax";
import { TiMessages } from "react-icons/all";

import bgImage from "../../../../../assets/images/proposal-2.jpg";

import { FAQs, styles } from "./constants";

const useStyles = makeStyles(styles);


export default function FAQSection() {
  const classes = useStyles();

  const ColorsBox = () => (
    <Grid container className={classes.colorBox} justify="center">
      <Grid item className={classes.deepLakeBg} />

      <Grid item>
        <Grid container style={{width: 150}}>
          <Grid item className={classes.onlineLimeBg} />

          <Grid item>
            <Grid container style={{width: 50}}>
              <Grid item className={classes.wisteriaBg} />
              <Grid item className={classes.stormyBg} />
            </Grid>
          </Grid>
          <Grid item className={classes.vintageVioletBg} />
          <Grid item className={classes.plumPurpleBg} />
          <Grid item className={classes.grayBg} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Parallax blur={{ min: -5, max: 5 }} bgImage={bgImage} strength={200}>
      <Grid container className={classes.section} spacing={3} alignItems="center" justify="center">
        <Grid item xs={12} className={classes.title}>
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.subtitle}>
                <TiMessages />
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h3">
                Frequently Asked Questions
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                As we get closer to the date, we will be periodically answering questions for
                you to stay up-to-date with the latest information
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Divider className={classes.titleDivider} variant="middle" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Grid container alignItems="center" justify="center" spacing={3}>
            {FAQs.map(({question, answer}) => (
              <Grid item md={4}>
                <Card className={classes.card}>
                  <CardHeader
                    className={classes.cardHeader}
                    title={question}
                    subheader={answer === "COLOR-BOX" ? <ColorsBox /> : answer}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Parallax>
  );
};
