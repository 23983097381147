export const styles = theme => ({
  section: {
    background: "#23565cd4",
    padding: "100px 5px"
  },
  title: {
    textAlign: "center",
    color: theme.palette.background.paper,
  },
  subtitle: {
    color: theme.palette.primary.light,
  },
  titleDivider: {
    height: 2,
    background: theme.palette.primary.light,
  },
  card: {
    boxShadow: theme.shadows[4],
    padding: theme.spacing(5),
  },
  iframe: {
    border: "none",
    minHeight: "80vh",
    width: "100%",
    position: "relative",
  },
  button: {
    textAlign: "center"
  },
});
