import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Parallax } from "react-parallax";

import bgImage from "../../../../../assets/images/rodeo-1.jpg";

import { styles } from "./constants";

const useStyles = makeStyles(styles);

export default function RodeoImageSection(props) {
  const classes = useStyles();
  return (
    <Parallax blur={{ min: -5, max: 5 }} bgImage={bgImage} strength={200}>
      <Grid container className={classes.section} />
    </Parallax>
  );
}
