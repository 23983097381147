export const styles = theme => ({
  section: {
    minHeight: "100vh",
    background: theme.palette.primary.light,
    padding: 0
  },
  image: {
    width: "100%",
    height: "100vh",
    boxShadow: "inset 0px 1px 40px #16393dfa",
    background: "#ffffff52",
  },
  bgImage: {
    filter: "saturate(0.4)",
  },
  title: {
    textAlign: "center",
    color: theme.palette.primary.main,
    padding: theme.spacing(5),
  },
  subtitle: {
    color: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  titleDivider: {
    height: 2,
    background: theme.palette.text.secondary,
  },
  formContainer: {
    background: theme.palette.background.default,
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(8),
  },
  formClosed: {
    opacity: 0,
    height: 0,
    padding: 0
  },
  iframe: {
    minHeight: "80vh"
  }
});
