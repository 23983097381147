import {deepLake, gray, onlineLime, plumPurple, stormy, vintageViolet, wisteria} from "../../../../../constants";


export const FAQs = [
  {
    "question": "When is the deadline to RSVP?",
    "answer": "We wish to have all RSVP's by April 28th, 2021 so that we can ensure all accommodations are accounted for.",
  },
  {
    "question": "Are there good hotels nearby?",
    "answer": "Yes! The venue is right in the heart of Uptown Charlotte which is close to many hotels at a variety of price ranges and amenities offered. We are currently compiling recommendations. Check back soon!",
  },
  {
    "question": "Are children invited?",
    "answer": "As much as we would love to invite all children, we regret that it is only possible for us to accommodate the children of close family.",
  },
  {
    "question": "What are the wedding colors?",
    "answer": "COLOR-BOX",
  },
  {
    "question": "Is there a dress code?",
    "answer": "We find that cocktail attire will most match the Uptown aesthetic of the venue and ceremony.",
  },
  {
    "question": "Will on-site parking be provided?",
    "answer": "While there is both on-street parking and nearby garages throughout Uptown Charlotte, we may find a solution that best fits our party at a reduced rate and convenient location. Check back soon for more details!",
  },
  {
    "question": "How far is the reception venue from the ceremony?",
    "answer": "The ceremony, cocktail hour, and reception are all located on the 5th floor of the Mint Museum. All locations are directly adjacent to each other.",
  },
  {
    "question": "What is the Menu?",
    "answer": "Our menu will feature a variety of hors d'oeuvres, small plates, and chef-accompanied stations. There will also be vegetarian and allergy-concious options served.",
  },
  {
    "question": "I've RSVP'd, now what?",
    "answer": "Unless we contact you via the primary email address provided in the RSVP, see you July 2nd, 2021!",
  },

];


export const styles = {
  section: {
    background: "#ffffffdb",
    padding: "100px 5px"
  },
  title: {
    textAlign: "center",
    color: deepLake,
  },
  subtitle: {
    color: wisteria,
  },
  titleDivider: {
    height: 2,
    background: deepLake,
  },
  card: {
    maxWidth: "90%",
    padding: 25
  },
  cardHeader: {
    color: deepLake,
  },
  colorBox: {
    padding: 5,
  },
  deepLakeBg: {
    height: 150,
    width: 150,
    background: deepLake,
  },
  wisteriaBg: {
    height: 50,
    width: 50,
    background: wisteria,
  },
  stormyBg: {
    height: 50,
    width: 50,
    background: stormy,
  },
  onlineLimeBg: {
    height: 100,
    width: 100,
    background: onlineLime,
  },
  plumPurpleBg: {
    height: 50,
    width: 50,
    background: plumPurple,
  },
  vintageVioletBg: {
    height: 50,
    width: 50,
    background: vintageViolet,
  },
  grayBg: {
    height: 50,
    width: 50,
    background: gray,
  },
};
