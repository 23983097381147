import React from "react";
import { Button, Card, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { Parallax } from "react-parallax";
import { AiOutlineGift } from "react-icons/all";

import bgImage from "../../../../../assets/images/met-1.jpg";

import { styles } from "./constants";

const useStyles = makeStyles(styles);


export default function RegistrySection() {
  const classes = useStyles();

  return (
    <Parallax blur={{ min: -5, max: 5 }} bgImage={bgImage} strength={200}>
      <Grid container className={classes.section} spacing={3} alignItems="center" justify="center">
        <Grid item xs={11} className={classes.title}>
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs={11}>
              <Typography variant="h2" className={classes.subtitle}>
                <AiOutlineGift />
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h2" className="--cursive">
                Our Wedding Registry
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Divider className={classes.titleDivider} variant="middle" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Card className={classes.card}>
            <iframe className={classes.iframe} src="https://widget.zola.com/v1/widget/registry/seals-nutt/html"/>
          </Card>
        </Grid>
        <Grid item xs={11} className={classes.button}>
          <Button variant="contained" href="/registry">
            Open Wedding Registry
          </Button>
        </Grid>
      </Grid>
    </Parallax>
  );
};
