import { museumAddress, guestEventAddress } from "../../../../../constants";


export const Schedule = [
  {
    name: "Friday, July 2nd",
    agenda: [
      {
        time: "5:30 - 6:00pm",
        title: "Arrival",
        details: "Guests will arrive on the 5th Floor",
        location: {
          name: "Mint Museum Uptown",
          address: museumAddress,
        }
      },
      {
        time: "6:00 - 6:30pm",
        title: "Ceremony",
        details: "The Wedding Ceremony will take place on the dramatic semi-covered Museum Terrace overlooking a stunning view of Uptown Charlotte",
        location: {
          name: "Mint Museum Uptown",
          address: museumAddress,
        }
      },
      {
        time: "6:30 - 7:30pm",
        title: "Cocktail Hour",
        details: "Enjoy light refreshments while the Bride and Groom take wedding photographs.",
        location: {
          name: "Mint Museum Uptown",
          address: museumAddress,
        }
      },
      {
        time: "7:30 - 10:30pm",
        title: "Reception",
        details: "The Reception will take place in the Grand Room of the Museum's 5th Floor. " +
          "Guests will enjoy an evening of music, dancing, and hors d'oeuvres.",
        location: {
          name: "Mint Museum Uptown",
          address: museumAddress,
        }
      },
    ]
  }
];
