import { createMuiTheme } from "@material-ui/core";

export const weddingDate = new Date('2021-07-02T22:00:00.000Z');
export const weddingDateLabel = weddingDate.toLocaleString("en-US", {timeZone: "America/New_York"});
export const googleRSVPFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSduxt8B9" +
  "q_x8KQgNns2A6lek9t5OyKVbMaNE35mXuDaj43wvA/viewform?embedded=true";
export const addEventId = "BS4654911";
export const addEventUrl = `https://www.addevent.com/event/${addEventId}`;
export const museumAddress = "500 South Tryon Street Charlotte, NC 28202";
export const guestEventAddress = "2241 Sonoma Valley Dr. Charlotte, NC 28214";
// Colors
export const deepLake = "#24565c";
export const onlineLime = "#e1f2e5";
export const stormy = "#36333b";
export const wisteria = "#997f83";
export const vintageViolet = "#4a1a3a";
export const plumPurple = "#321028";
export const white = "#fff";
export const gray = "#808080";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: onlineLime,
      main: deepLake,
    },
    secondary: {
      light: vintageViolet,
      main: plumPurple,
    },
    text: {
      primary: stormy,
      secondary: wisteria,
    }
  }
});

export const locationWebsite = "https://mintmuseum.org/";
export const locationMapUrl = "https://goo.gl/maps/anBrDdBtFUoNT8eR9";
export const locationEmbedUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d104237.22743021412!2d-80.98724354087538!3d35.270847717764006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8856a0294f3900e9%3A0x319cde9c1326f0c1!2sMint%20Museum%20UPTOWN!5e0!3m2!1sen!2sus!4v1577996702545!5m2!1sen!2sus";
