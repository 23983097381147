import React from 'react';
import Helmet from 'react-helmet';

const defaultTitle = '7-2-2021';

export default function PageTitle({ title }){
  return (
    <Helmet>
      <title>Seals-Nutt Wedding | {title ? title : defaultTitle}</title>
    </Helmet>
  );
}
