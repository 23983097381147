import React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Parallax } from "react-parallax";

import bgImage from "../../../../../assets/images/engage-1.jpg";
import Countdown from "../../../../Presentational/Countdown";

import { styles } from "./constants";

const useStyles = makeStyles(styles);

export default function IntroSection(props) {
  const classes = useStyles();
  return (
    <Parallax blur={{ min: -5, max: 5 }} bgImage={bgImage} strength={300}>
      <Grid container className={classes.section} spacing={0} direction="column" alignItems="center" justify="center">
        <Grid item xs={11} md={7}>

          <Grid container className={classes.container} spacing={7} alignItems="center" justify="center">
            <Grid item xs={12} md={8}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={3} md={4}>
                  <Divider variant="middle" className={classes.subtitleDivider}/>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle1" color="textSecondary" >
                    THE WEDDING OF
                  </Typography>
                </Grid>
                <Grid item xs={3} md={4}>
                  <Divider variant="middle" className={classes.subtitleDivider}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Typography  variant="h1" color="primary" className="--cursive">
                Kenneth & Vanessa
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider variant="middle"/>
            </Grid>
            <Grid item xs={12}>
              <Countdown />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Parallax>
  );
}
