import * as actionTypes from "./actions/types";
const initialState = {
  token: null,
  user: null,
  error: null,
  loading: false,
  authenticated: false, // Check if the user is logged in
  pageAuthorized: true, // Check if a page can be viewed
  isAdmin: false,
  msg: "",
  projects: null,
  currentProject: null,
};

const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_START:
      return updateObject(state, { error: null, loading: true, user: null });

    case actionTypes.SIGNUP_SUCCESS:
      return updateObject(state, {token: action.token, expiresIn: action.expiresIn, user: action.user,
                                  error: null, loading: false, authenticated: true});

    case actionTypes.SIGNUP_FAIL:
      return updateObject(state, { error: action.error,  msg: "Signup Failed", loading: false, user: null });

    case actionTypes.SIGNIN_START:
      return updateObject(state, { error: null, loading: true, user: null, token: null });

    case actionTypes.SIGNIN_SUCCESS:
      return updateObject(state, { token: action.token, user: action.user,
                                  error: null, loading: false, authenticated: true });

    case actionTypes.SIGNIN_FAIL:
      return updateObject(state, { error: action.error,  msg: "Login Failed", loading: false, user: null });

    case actionTypes.PAGE_AUTHORIZATION_FAILED:
      return updateObject(state, { pageAuthorized: false, loading: false, msg: action.msg});

    case actionTypes.AUTHENTICATION_FAILED:
      return updateObject(state, {token: null, user: null, authenticated: false, loading: false});

    case actionTypes.SIGNOUT_START:
      return updateObject(state, { error: null, loading: true });

    case actionTypes.SIGNOUT_SUCCESS:
      return initialState;

    case actionTypes.GET_CURRENT_PUBLISHER:
      return updateObject(state, { authenticated: true, pageAuthorized: true, user: action.user, projects: action.projects, error: null, loading: false });

    case actionTypes.UPDATE_PASSWORD:
      return updateObject(state, { authenticated: true, pageAuthorized: true, loading: false });

    case actionTypes.UPDATE_PUBLISHER:
      return updateObject(state, { loading: false });

    case actionTypes.CREATE_COLLECTION:
      return updateObject(state, { currentProject: action.currentProject, error: action.error, msg: action.msg, loading: false });

    case actionTypes.GET_COLLECTION:
      return updateObject(state, { collection: action.collection, error: action.error, msg: action.msg, loading: action.loading });

    case actionTypes.CREATE_PROJECT:
      return updateObject(state, { currentProject: action.currentProject, error: action.error, msg: action.msg, loading: false });

    case actionTypes.GET_PROJECT:
      return updateObject(state, { currentProject: action.currentProject, error: action.error, msg: action.msg, loading: false });

    case actionTypes.ADD_PUBLISHER_TO_PROJECT:
      return updateObject(state, { currentProject: action.currentProject, error: action.error, msg: action.msg, loading: false });

    default:
      return state;
  }
};

export default reducer;
