import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import { createBrowserHistory } from "history";
import { SnackbarProvider } from 'notistack';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import routes from "./routes";
import './App.scss';
import PageTitle from "./Components/Container/Page/Title";
// import NavbarContainer from "./Components/Container/Navbar";
// import PageTitle from "./Components/Container/Page/Title";
// import logo from './logo.svg';
import { theme } from './constants';
// const hist = createBrowserHistory();
// Note: URL Base pattern need to be the same as the path of the django app
function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <PageTitle />
        <div className="App">
          {routes}
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default withRouter(connect()(App));
