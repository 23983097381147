//signup
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

//signin
export const SIGNIN_START = "SIGNIN_START";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

//signout
export const SIGNOUT_START = "SIGNOUT_START";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";

//check auth timeout
export const PAGE_AUTHORIZATION_FAILED = "PAGE_AUTHORIZATION_FAILED";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";

//Collection Actions
export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const GET_COLLECTION = "GET_COLLECTION";

//Project Actions
export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_PROJECT = "GET_PROJECT";
export const ADD_PUBLISHER_TO_PROJECT = "ADD_PUBLISHER_TO_PROJECT";

//Publisher Actions
export const GET_CURRENT_PUBLISHER = "GET_CURRENT_PUBLISHER";
export const UPDATE_PUBLISHER = "UPDATE_PUBLISHER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
